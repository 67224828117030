import { Col, Divider, List } from "antd";
import React from "react";

import "src/components/whyClimateCoating/style.scss";

const data = [
  {
    title: "Saludable y Respetuoso con el Medio Ambiente",
    content:
      "Los productos cumplen con uno de los estándares más estrictos del mundo en cuanto a emisiones químicas y cuentan con el certificado “Greenguard” en oro. El uso de nuestros productos ayuda a reducir el consumo de CO 2 porque se requiere menos energía para calentar o enfriar. Y puede confiar en que cumplimos con los altos requisitos de calidad y gestión medioambiental según DIN EN ISO 9001: 2015 y 14001: 2015.",
  },
  {
    title: "Ahorro energético efectivo y elevada duración",
    content:
      "La tecnología del producto, la receta y los componentes de alta calidad de la dispersión crearon propiedades tales como una muy alta reflexión de la luz solar y una estabilidad duradera del color, resistencia a la suciedad y robustez, elasticidad y durabilidad. Las pinturas y pinturas para paredes ofrecen una durabilidad y estabilidad superiores a la media en las que puede confiar.",
  },
  {
    title: "Eficiente protección de la edificación",
    content:
      "Los productos ClimateCoating ® ofrecen soluciones prácticas a los problemas. El moho en las paredes, las algas, el musgo, las fachadas quebradizas y sucias se combinan, previenen o evitan eficazmente. Se reduce significativamente el calentamiento de techos, interiores, contenedores o tanques. Todos los efectos positivos se muestran como ventajas convincentes de tiempo, presupuesto y atractivo para usted.",
  },
];

export const WhyClimateCoating = (props) => {
  return (
    <div className="ccWhy">
      <Col span={24}>
        <h2 className="ccWhyTitleText">
          ClimateCoating® - Mucho más que "una pintura"
        </h2>

        <List
          {...{
            grid: { gutter: 10, xs: 1, sm: 1, md: 3, lg: 3, xl: 3, xxl: 3 },
            dataSource: data,
            renderItem: (item) => (
              <List.Item>
                <Col>
                  <h3 className="reasonTitle">{item.title}</h3>
                  <span className="reasonDividerWrapper">
                    <Divider plain />
                  </span>
                  <span className="reasonContent">{item.content}</span>
                </Col>{" "}
              </List.Item>
            ),
          }}
        />
      </Col>
    </div>
  );
};

export default WhyClimateCoating;
