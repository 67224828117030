import React from "react";

import { Avatar, Col, Card, List } from "antd";

import "src/components/certificates/style.scss";
import greengardImg from "src/components/certificates/images/smallSize/certificado_greenguard.jpg";
import eficienciaEnergeticaAlemanaImg from "src/components/certificates/images/smallSize/certificado_energie_effizienz_made_in_germany.jpg";
import germanInnovationAwardImg from "src/components/certificates/images/smallSize/certificado_german_innovation.jpg";
import certificadoAecImg from "src/components/certificates/images/smallSize/certificado_aec.jpg";
import certificadoAecIqnet from "src/components/certificates/images/smallSize/certificado_iqnet.jpg";
import certificadoEnergyGlobe from "src/components/certificates/images/smallSize/certificado_energy_globe.jpg";

const { Meta } = Card;

export const CertificatesComponent = (props) => {
  const data = [
    {
      title: "Greenguard",
      imgSrc: greengardImg,
      imgAlt: "ThermoPlus",
    },
    {
      title: "Eficiencia Energética",
      imgSrc: eficienciaEnergeticaAlemanaImg,
      imgAlt: "ThermoProtect",
    },
    {
      title: "Premio a la innovación",
      imgSrc: germanInnovationAwardImg,
      imgAlt: "ThermoActive",
    },
    {
      title: "Certificado AEC",
      imgSrc: certificadoAecImg,
      imgAlt: "IndustrySpecial",
    },
    {
      title: "Certificado Iqnet",
      imgSrc: certificadoAecIqnet,
      imgAlt: "IndustrySpecial",
    },
    {
      title: "Energy Globe",
      imgSrc: certificadoEnergyGlobe,
      imgAlt: "IndustrySpecial",
    },
  ];

  return (
    <div className="certificates">
      <List
        {...{
          grid: { gutter: 20, xs: 2, sm: 2, md: 3, lg: 6, xl: 6, xxl: 6 },
          dataSource: data,
          renderItem: (item) => (
            <List.Item>
              <Col xs={{ offset: 0 }} sm={{ offset: 0 }}>
                <Card
                  {...{
                    bordered: false,
                    title: <h4 className="certificateName">{item.title}</h4>,
                    cover: (
                      <img
                        {...{
                          alt: item.imgAlt,
                          src: item.imgSrc,
                        }}
                      />
                    ),
                  }}
                ></Card>
              </Col>
            </List.Item>
          ),
        }}
      />
    </div>
  );
};

export default CertificatesComponent;
