import React from "react";
import "src/index.scss";

import { Layout } from "antd";

import { LayoutGeneral } from "src/layout/LayoutGeneral";

import Slider from "src/components/slider/Component";
import ProductCategoriesComponent from "src/components/productCategories/Component";
import CorporateMessageComponent from "src/components/corporateMessage/Component";

import WhyClimateCoating from "src/components/whyClimateCoating/Component";
import CertificatesComponent from "src/components/certificates/Component";
import Video from "../components/Video";

const { Header, Content } = Layout;

const IndexPage = (props) => {
  return (
    <LayoutGeneral
      {...{
        ...props,
        showPageTitle: false,
        pageTitle: "ClimateCoating España",
        pageName: "ClimateCoating España",
        pageDescription:
          "ClimateCoating: Membrana Termocerámica con redimiento endotérmico para exteriores, interiores, aplicaciones industriales, cubiertas y tejados",
      }}
    >
      <Slider />
      <Content className="home">
        <CorporateMessageComponent />
        <ProductCategoriesComponent />
        <WhyClimateCoating />
        <CertificatesComponent />
      </Content>
    </LayoutGeneral>
  );
};

export default IndexPage;
