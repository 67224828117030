import { Col, Divider } from "antd";
import React from "react";

import "src/components/corporateMessage/style.scss";

export const CorporateMessageComponent = (props) => {
  return (
    <div className="ccCorporateMessage">
      <Col
        {...{
          offset: 1,
          span: 22,
        }}
      >
        <h1>ClimateCoating ®</h1>
        <span className="mainText">
          Descubre las ventajas de la membrana termocerámica de ClimateCoating,
          disfruta de un producto ecológico y saludable que te ahorrará consumo
          energético mientras protege tu edificación y mejora su aspecto
        </span>
        <span className="mainSubtext">
          Fabricado en Alemania. Fabricado para tí.
        </span>
        <Divider plain />
      </Col>
    </div>
  );
};

export default CorporateMessageComponent;
