import React from "react";
import { Carousel } from "antd";

import "src/components/slider/style.scss";

class Slider extends React.Component {
  onChange(a, b, c) {
    console.log(a, b, c);
  }

  render() {
    return (
      <Carousel afterChange={this.onChange}>
        {
          //Ini: Slide 1
        }
        <div>
          <div className="slideContentWrapper siccSlide">
            <div className="sliderTextStyleWrapper">
              <div className="sliderText">
                <span className="sliderTextTitle">
                  Revestimientos ClimateCoating
                </span>
                <span className="sliderTextContent">
                  Ahorro energético y respeto al Medio Ambiente con la membrana
                  reflectante
                </span>
              </div>
            </div>
          </div>
        </div>
        {
          //End: Slide 1
        }

        {
          //Ini: Slide 2
        }
        <div>
          <div className="slideContentWrapper thermoActiveSlide">
            <div className="sliderTextStyleWrapper">
              <div className="sliderText">
                <span className="sliderTextTitle">
                  Refleja hasta un 91,4% de la luz solar
                </span>
                <span className="sliderTextContent">
                  Cubiertas/Tejados más frescos gracias a ThermoActive
                </span>
              </div>
            </div>
          </div>
        </div>
        {
          //End: Slide 2
        }

        {
          //Ini: Slide 3
        }
        <div>
          <div className="slideContentWrapper CCFamilySlide">
            <div className="sliderTextStyleWrapper">
              <div className="sliderText">
                <span className="sliderTextTitle">
                  Elija el producto más adecuado a sus necesidades
                </span>
                <span className="sliderTextContent">
                  Ahorre no sólo en eneregía sino en mantenimiento
                </span>
              </div>
            </div>
          </div>
        </div>
        {
          //End: Slide 3
        }
      </Carousel>
    );
  }
}

export default Slider;

/*
        <div>
          <span style={contentStyle}>
            {
              // <img src={CCFamily} alt="thermoActive ejemplo" />
            }
            Familia de productos
          </span>
        </div>
        <div>
          <span style={contentStyle}>
            {
              // <img src={SiccCoatings} alt="thermoActive ejemplo" />
            }{" "}
            Sicc Coatings
          </span>
        </div> */
