import React from "react";

import { Avatar, Col, Card, List } from "antd";
import { Link } from "gatsby";
import "src/components/productCategories/style.scss";
import interiorImg from "src/components/productCategories/images/interior.png";
import exteriorImg from "src/components/productCategories/images/exterior.png";
import cubiertasImg from "src/components/productCategories/images/cubiertas.png";
import industrialImg from "src/components/productCategories/images/industrial.png";
import humedadesImg from "src/components/productCategories/images/humedades.png";

import thermoPlusAvatarImg from "src/components/products/images/thermoPlusAvatar.jpg";
import thermoProtectAvatarImg from "src/components/products/images/thermoProtectAvatar.jpg";
import thermoActiveAvatarImg from "src/components/products/images/thermoActiveAvatar.jpg";
import industrySpecialAvatarImg from "src/components/products/images/industrySpecialAvatar.jpg";
import thermoVitalAvatarImg from "src/components/products/images/thermoVitalAvatar.jpg";

const { Meta } = Card;

export const ProductCategoriesComponent = ({
  xs = 1,
  sm = 2,
  md = 2,
  lg = 2,
  xl = 5,
  xxl = 5,
}) => {
  const data = [
    {
      title: "Para Interiores",
      list: [
        "Ecológica, nula toxicidad",
        "Confort - regulación de humedad",
        "Certificación Greengard",
      ],
      imgSrc: interiorImg,
      imgAlt: "ThermoPlus",
      productAvatarSrc: thermoPlusAvatarImg,
      productTitle: "ThermoPlus",
    },
    {
      title: "Para Exterior/Fachadas",
      list: [
        "Ahorro Energético",
        "Mejora Estética",
        "Protección de la edificación",
      ],
      imgSrc: exteriorImg,
      imgAlt: "ThermoProtect",
      productAvatarSrc: thermoProtectAvatarImg,
      productTitle: "ThermoProtect",
    },
    {
      title: "Cubierta/Tejados",
      list: [
        "Altísima Reflexibidad",
        "Reacción endotérmica",
        "Protección frente a fisuras",
      ],
      imgSrc: cubiertasImg,
      imgAlt: "ThermoActive",
      productAvatarSrc: thermoActiveAvatarImg,
      productTitle: "ThermoActive",
    },
    {
      title: "Industrial",
      list: [
        "Testado en condiciones extremas",
        "Usado alrededor del mundo",
        "Certificaciones de calidad",
      ],
      imgSrc: industrialImg,
      imgAlt: "IndustrySpecial",
      productAvatarSrc: industrySpecialAvatarImg,
      productTitle: "IndustrySpecial",
    },
    {
      title: "Humedades",
      list: [
        "Específico para condensaciones",
        "Aumento de la salubridad",
        "Certificaciones de calidad",
      ],
      imgSrc: humedadesImg,
      imgAlt: "ThermoVital",
      productAvatarSrc: thermoVitalAvatarImg,
      productTitle: "ThermoVital",
    },
  ];

  return (
    <div className="ccContent productCategories">
      <List
        {...{
          grid: { gutter: 10, xs, sm, md, lg, xl, xxl },
          dataSource: data,
          renderItem: (item) => (
            <List.Item>
              <Col xs={{ offset: 0 }} sm={{ offset: 0 }}>
                <Card
                  {...{
                    bordered: false,
                    title: (
                      <h4 className="productCategoryTitle">{item.title}</h4>
                    ),
                    cover: (
                      <img
                        {...{
                          alt: item.imgAlt,
                          src: item.imgSrc,
                        }}
                      />
                    ),
                  }}
                >
                  <Meta
                    {...{
                      avatar: (
                        <Avatar src={item.productAvatarSrc} shape="square" />
                      ),
                      title: (
                        <span className={`productTitle ${item.productTitle}`}>
                          <Link
                            to={`/productos/${item.productTitle.toLowerCase()}`}
                          >
                            {item.productTitle}
                          </Link>
                        </span>
                      ),
                      description: (
                        <ul style={{ paddingLeft: "5px" }}>
                          {item.list.map((li, j) => (
                            <li key={j}>{li}</li>
                          ))}
                        </ul>
                      ),
                    }}
                  />
                </Card>
              </Col>
            </List.Item>
          ),
        }}
      />
    </div>
  );
};

export default ProductCategoriesComponent;
